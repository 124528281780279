$light-brown-1: #FFE1C3;
$light-brown-2: #FFECD9;
$light-brown-3: #FFFBF6;
$brown: #9C4E00;
$dark-brown-1: #5A2D00;
$dark-brown-2: #3D1E00;
$orange: #FFA449;
$light-orage: #FFC183;
$light-orage-2: #FFEBD8;
$red: #C30000;
$blue: #0011A9;
$sky-blue: #1F8AC8;
$light-blue: #EAF0F7;
$dark-gray: #686868;
$gray-1: #666666;
$gray-2: #ACACAC;
$light-gray-1: #CFCFCF;
$light-gray-2: #F5F5F5;

$kakao: #FFEF7A;