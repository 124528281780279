@import '/src/style/Color.scss';

#notice-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1:first-child {
        color: $dark-brown-2;
        font-size: 25px;
        font-family: 'BM Dohyeon';
        margin-top: 100px;
    }
    h1:last-child {
        color:$gray-1;
        font-size: 20px;
        font-family: 'BM Dohyeon';
        margin-top: 10px;
    }
    #notice-list-horizontal-line {
        height: 0.5px;
        width: 100%;
        background-color: $dark-gray;
        margin-top: 28px;
        margin-bottom: 36px;
    }

    ul {
        margin-left: 22.5%;
        margin-right: 22.5%;
        width: 55%;

        @media ALL and (max-width:767px) {
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;
        }

        > a {
            text-decoration: none;
            > li {
                margin-bottom: 3px;
                width: 100%;
                height: 20%;
                border-radius: 10px;
                border: solid 1px rgb(167, 167, 167);
                box-sizing: border-box;
                padding: 18px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                >div:first-child {
                    display: flex;
                    align-items: flex-end;
    
                    h3 {
                        font-size: 24px;
                        color: $brown;
                        font-family: 'BM Hanna Pro';
    
                        @media ALL and (max-width:767px) {
                            font-size: 22px;
                        }
                    }
    
                    div {
                        margin-left: 8px;
                        font-size: 14px;
                        color: $gray-1;
                        font-family: 'BM Hanna Air';
                    }
                }
    
                >div:last-child {
                    margin-top: 1%;
                    font-size: 14px;
                    font-family: 'BM Hanna Air';
                    color: black;
                }
            }
        }

        margin-bottom: 70px;
    }
}