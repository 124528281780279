@import '/src/style/Color.scss';

#notice-shop-button {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    margin-top: 18px;
    z-index: 1;

    a {
        display: inline-block;
        text-align: center;
        margin-left: 35px;
        height: 30px;
        width: 80px;
        line-height: 30px;
        background-color: $light-orage;
        border-radius: 30px;
        text-decoration: none;
        font-family: 'BM Jua';
        font-size: 18px;
        color: $dark-brown-1;
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media (min-width:768px) and (max-width:1023px) {
    #notice-shop-button {
        display: flex;
    }
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media (min-width:480px) and (max-width:1024px) {
    #notice-shop-button{
        display: none;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    #notice-shop-button{
        display: none;
    }
}