@import '/src/style/Color.scss';

#main-pop-up-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    #main-pop-up {
        border-radius: 40px;
        background-color: white;
        padding: 55px 70px 20px;
        font-family: 'Blinker';
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
        height: 240px;

        @media (min-width:500px) and (max-width:800px) {
            max-width: 220px;
        }
        @media (min-width:290px) and (max-width:500px) {
            max-width: 200px;
        }

        >h2 {
            margin-top: -6%;
            font-size: 27px;
            font-weight: bold;
        }

        #main-pop-up-contents {
            font-size: 20px;
            color: $gray-1;
            line-height: 24px;
            margin-top: 20px;
        }

        #main-pop-up-buttons {
            display: flex;
            column-gap: 20px;
            margin-top: 60px;
            @media (min-width:300px) and (max-width:800px) {
                margin-top: 30px;
            }

            >button {
                cursor: pointer;
                font-size: 15px;
                border-radius: 40px;
                border: 1px solid $gray-2;
                width: 140px;
                height: 40px;
                color: white;
                background-color: $light-orage;
            }
        }

        #do-not-show {
            color: $gray-2;
            text-decoration: underline $gray-2;
            margin-top: 20px;
            cursor: pointer;
            user-select: none;
        }

        #main-pop-up-line {
            height: 2px;
            width: 30px;
            background-color: $orange;
            margin-top: 28px;
        }
    }
}