@import '/src/style/Color.scss';

#ai-survey {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    > form {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            column-gap: 120px;
            row-gap: 50px;
            
            > div {
                &:first-child {
                    width: 80%;
                    > label {
                        font-size: 16px !important;
                        div {
                            font-size: 16px !important;
                        }
                    }
                    max-width: 440px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 10px;
                    > h1 {
                        font-size: 3rem;
                        font-weight: 600;
                        font-family: 'Blinker';
                    }
                    > div {
                        font-size: 2rem;
                        font-family: 'Blinker';
                    }
                    @media (max-width: 500px) {
                        > h1 {
                            font-size: 1.5rem;
                            font-weight: 600;
                            font-family: 'Blinker';
                        }
                        > div {
                            font-size: 1rem;
                            font-family: 'Blinker';
                        }
                    }
                }
                &.option-select:last-child {
                    @media (max-width: 500px) {
                        > label {
                            font-size: 16px !important;
                            div {
                                font-size: 16px !important;
                            }
                        }
                    }
                }
            }
        }
        > a {
            margin-top: 80px;
            width: 300px;
            max-width: 400px;
            > button {
                width: 100%;
                height: 70px;
                border: none;
                border-radius: 10px;
                background-color: $light-orage;
                color: white;
                font-family: 'Blinker';
                font-weight: 700;
                font-size: 20px;
                @media (max-width:690px) {
                    width: 300px;
                }
            }
        }
    }
}