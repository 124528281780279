@import '/src/style/Color.scss';

#attendance-check-pop-up-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    #attendance-check-pop-up {
        width: 600px;
        box-sizing: border-box;

        #attendance-check-alert {
            font-family: 'Blinker';
            padding: 32px 30px 52px;
            background-color: white;
            border-radius: 20px 20px 0 0;

            >h2 {
                font-size: 30px;
                font-weight: bold;
            }

            >div {
                font-size: 24px;
                color: $gray-1;
                margin-top: 36px;
            }
        }

        #attendance-check-button {
            width: 100%;
            padding: 25px;
            border: none;
            border-radius: 0 0 20px 20px;
            background-color: $light-orage;
            color: white;
            font-family: 'BM Jua';
            font-size: 24px;
        }
    }
}