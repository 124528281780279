@import '/src/style/Color.scss';

#event {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
        font-size: 25px;
        font-family: 'BM Dohyeon';
        color: $dark-brown-2;
    }
    #event-line {
        width: 100%;
        height: 0.5px;
        background-color: $dark-gray;
        margin-top: 28px;
        margin-bottom: 24px;
    }
    #events {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 32px;
        row-gap: 56px;

        > li {
            >a{
                text-decoration: none;
                > img{
                    border-radius: 25px;
                    width: 100%;
                }
                > h2 {
                    margin-top: 16px;
                    font-size: 25px;
                    font-family: 'BM Dohyeon';
                    text-decoration: none;
                    color: $dark-brown-2;
                }
                > div{
                    text-decoration: none;
                    color: $dark-brown-2;
                }
            }
        }
        padding-bottom: 70px;
    }
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media (min-width:768px) and (max-width:1023px) {
    #event {
        #events {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 32px;
            row-gap: 56px;
    
            > li {
                > h2 {
                    margin-top: 16px;
                    font-size: 25px;
                    font-family: 'BM Dohyeon';
                }
            }
            padding-bottom: 70px;
        }
    }
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media ALL and (max-width:767px) {
    #event {
        margin-top: 40px;
        #events {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 32px;
            row-gap: 56px;
    
            > li {
                > h2 {
                    margin-top: 16px;
                    font-size: 25px;
                    font-family: 'BM Dohyeon';
                }
            }
            padding-bottom: 70px;
        }
    }
}
