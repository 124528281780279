@import '/src/style/Color.scss';

#extra-header {
    height: 63px;
    width: 100vw;
    background-color: $light-orage;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
}