@import '/src/style/Color.scss';

#payment {
    display: flex;
    flex-direction: column;

    > h1 {
        margin-top: 50px;
        margin-left: 25px;
        font-size: 30px;
        font-family: 'Blinker';
        font-weight: 600;
    }
    #payment-line {
        width: 100%;
        height: 0.5px;
        background-color: $dark-gray;
        margin-top: 19px;
        margin-bottom: 50px;
    }
    > form {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 230px;
        > div {
            margin-bottom: 28px;
            > h2 {
                font-size: 20px;
                font-family: 'Blinker';
                font-weight: 600;
                margin-bottom: 15px;
            }
            > ul {
                > li {
                    display: grid;
                    grid-template-columns: 175px 1225px;
                    height: 45px;
                    line-height: 45px;

                    > h3 {
                        background-color: $light-brown-2;
                        font-size: 18px;
                        font-family: 'Blinker';
                        font-weight: 600;
                        text-align: right;
                        box-sizing: border-box;
                        padding-right: 18px;
                        border-right: 1px solid $gray-2;
                        border-top: 1px solid $gray-2;
                    }
                    > div {
                        border-top: 1px solid $gray-2;
                    }
                }
            }
        }
        #payment-info {
            margin-bottom: 36px;
        }
        #purchase-book-type {
            display: flex;
            > label {
                height: 220px;
                width: 270px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                background-color: $light-brown-2;
                border-radius: 5px;
                margin-right: 56px;
                
                > div {
                    align-self: stretch;
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 18px 0 18px;

                    > div {
                        > h3 {
                            margin-bottom: 10px;
                            font-size: 20px;
                            font-family: 'Blinker';
                            font-weight: 700;
                        }
                        > div {
                            font-size: 12px;
                            font-family: 'BM Dohyeon';
                            color: $gray-1;
                        }
                    }
                    > input {
                        width: 25px;
                    }
                }
                > img {
                    margin-bottom: 18px;
                }
            }
            #monthly-book-purchases {
                width: 760px;
                height: 220px;
                background-color: $light-brown-2;
                border-radius: 5px;

                > div {
                    height: 155px;
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    box-sizing: border-box;
                    padding: 20px;

                    > h3 {
                        font-size: 22px;
                        font-weight: 700;
                        font-family: 'Blinker';
                    }
                }
            }
        }
        > button {
            width: 400px;
            height: 70px;
            border: none;
            border-radius: 10px;
            background-color: $light-orage;
            color: white;
            font-family: 'Blinker';
            font-weight: 700;
            font-size: 20px;
        }
    }
}