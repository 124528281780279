@import '/src/style/Color.scss';

#sign-up-pop-up--bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);

    #sign-up-pop-up--container {
        border: 1px solid #000;
        background-color: white;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 2.5rem;

        > h1 {
            font-size: 1.5rem;
            font-family: 'Blinker';
            margin-bottom: 1.5rem;
            text-align: center;
        }

        > button {
            cursor: pointer;
            font-size: 15px;
            border-radius: 40px;
            border: 1px solid $gray-2;
            width: 140px;
            height: 40px;
            color: white;
            background-color: $light-orage;
        }
    }
}