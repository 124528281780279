@import '/src/style/Color.scss';

#my-page {
    padding-top: 5px;
    padding-bottom: 65px;
    #my-page-header {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-left: 24px;

        >img {
            width: 80px;
            @media ALL and (max-width:800px) {
                width: 50px;
            }
        }

        >div {
            margin-left: 20px;

            >h2 {
                font-family: 'BM Jua';
                font-size: 35px;
                color: $dark-brown-2;
                @media ALL and (max-width:800px) {
                    font-size: 22px;
                }
            }

            >div {
                font-family: 'BM Jua';
                @media ALL and (max-width:800px) {
                    font-size: 22px;
                }
            }
        }
    }

    #fix-logout-button {
        margin-top: 28px;
        margin-left: 24px;
        display: flex;
        height: 35px;

        >div {
            width: 150px;
            color: white;
            font-size: 14px;
            font-family: 'BM Jua';
            background-color: $light-orage;
            line-height: 35px;
            text-align: center;
            margin-right: 15px;
        }
    }

    .my-page-line {
        height: 0.5px;
        width: 100%;
        background-color: $dark-gray;
        margin-top: 24px;
        margin-bottom: 48px;
    }

    #book-reports, #read-books, #joined-events {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
            font-size: 22px;
            font-family: 'BM Dohyeon';
            color: $dark-brown-2;
            @media ALL and (max-width:600px) {
                font-size: 20px;
            }
        }

        >ul {
            @media ALL and (max-width:600px) {
                padding-left: 25px;
                padding-right: 25px;
            }
            padding-left: 45px;
            padding-right: 45px;
            display: flex;
            width: 100%;
            box-sizing: border-box;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;

            &::-webkit-scrollbar{
                display:none;
            }
        }
    }

    #book-reports {
        >ul {
            > li {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 20px;
                font-family: 'BM Jua';

                > h4 {
                    margin-top: 8px;
                    font-size: 25px;
                }
                > div {
                    font-size: 16px;
                    color: $gray-1;
                }
                @media ALL and (max-width:600px) {
                    > h4 {
                        margin-top: 10px;
                        font-size: 18px;
                    }
                    > div {
                        font-size: 12px;
                        color: $gray-1;
                    }
                }
                >img{
                    @media ALL and (max-width:600px) {
                        width: 300px;
                        height: auto;
                    }
                }
            }
        }
    }
    #read-books {
        >ul {
            > li{
                margin-right: 20px;
                font-family: 'BM Jua';
                >img{
                    @media ALL and (max-width:600px) {
                        width: 200px;
                        height: auto;
                    }
                }
                > h4 {
                    margin-top: 8px;
                    font-size: 25px;
                }
                > div {
                    font-size: 16px;
                    color: $gray-1;
                }
                @media ALL and (max-width:600px) {
                    > h4 {
                        margin-top: 10px;
                        font-size: 18px;
                    }
                    > div {
                        font-size: 12px;
                        color: $gray-1;
                    }
                }
            }
        }
    }
    #joined-events {
        >ul {
            > li {
                display: flex;
                flex-direction: column;
                margin-right: 20px;
                font-family: 'BM Dohyeon';

                >img{
                    @media ALL and (max-width:600px) {
                        width: 300px;
                        height: auto;
                    }
                }
                > h4 {
                    margin-top: 8px;
                    font-size: 25px;
                }
                > div {
                    font-size: 16px;
                    color: $gray-1;
                }
                @media ALL and (max-width:600px) {
                    > h4 {
                        margin-top: 10px;
                        font-size: 18px;
                    }
                    > div {
                        font-size: 12px;
                        color: $gray-1;
                    }
                }
            }
        }
    }
}