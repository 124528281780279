@import '/src/style/Color.scss';

#sign-in {
    form {
        position: relative;
        width: 80%;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 72px;

        #input-form {
            display: grid;
            grid-template-rows: 1fr 1fr;
            row-gap: 30px;
            > .email {
                margin-top: 100px;
                display: flex;
                flex-direction: column;

                > label {
                    font-size: 18px;
                    margin-bottom: 14px;
                }
                > input {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    box-sizing: border-box;
                    padding: 0 20px;
                    background-color: $light-blue;
                    border-radius: 5px;
                }
            }
        }
        #logins {
            margin-top: -160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            > button {
                width: 100%;
                max-width: 400px;
                height: 70px;
                border: none;
                border-radius: 10px;
                background-color: $light-orage;
                color: white;
                font-family: 'Blinker';
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
}