@import '/src/style/Color.scss';

#view-question {
    margin-top: 30px;
    font-family: 'BM Dohyeon';
    color: $brown;
    display: flex;
    flex-direction: column;
    align-items: center;
    > h1 {
        font-size: 50px;
        @media (max-width:850px) {
            font-size: 33px;
        }
    }
    > ul {
        @media (max-width:850px) {
            width: 95%;
            margin-left: 2.5%;
            margin-right: 2.5%;
        }
        width: 600px;
        margin-top: 20px;
        margin-bottom: 100px;
        > li {
            margin-bottom: 5px;
            > input { display: none; }
            > input[type=checkbox]:checked ~ div {
                display: block;
            }
            > input[type=checkbox]:checked ~ label > img {
                transform: rotate(45deg);
            }
            > label {
                background-color: $light-brown-2;
                color: $brown;
                height: 86px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-bottom: 5px;
                @media (max-width:500px) {
                    height: 64px;
                }
                > h2 {
                    font-family: 'BM Dohyeon';
                    font-size: 25px;
                    line-height: 32px;
                    @media (max-width:500px) {
                        font-size: 17px;
                    }
                }
            }
            > div {
                background-color: $light-brown-2;
                display: none;
                color: $brown;
                font-family: 'Blinker';
                border-radius: 10px;
                padding: 30px;
                line-height: 20px;
                font-weight: 600;
            }
        }
    }
}
