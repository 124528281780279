@import '/src/style/Color.scss';

#attendancecheck {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
        font-size: 25px;
        font-family: 'BM Dohyeon';
        color: $dark-brown-2;
    }
    #attendancecheck-line {
        width: 100%;
        height: 0.5px;
        background-color: $dark-gray;
        margin-top: 28px;
        margin-bottom: 90px;
    }
    #calendar {
        #dates {
            display: flex;
            column-gap: 15px;
            > div {
                width: 100px;
                text-align: center;
                font-size: 26px;
                font-family: 'BM Jua';
                color: $orange;
                @media (max-width: 800px) {
                    width: 60px;
                    font-size: 20px;
                }
                @media (max-width: 560px) {
                    width: 40px;
                    font-size: 16px;
                }
                @media (max-width: 400px) {
                    width: 30px;
                    font-size: 12px;
                }
            }
        }
        #days {
            display: grid;
            grid-template-columns: repeat(7, 100px);
            grid-template-rows: repeat(5, 100px);
            column-gap: 15px;
            row-gap: 16px;
    
            > li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                text-align: center;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                font-size: 26px;
                font-family: 'BM Jua';
                &:nth-child(7n - 1) {
                    color: $blue;
                }
                &:nth-child(7n) {
                    color: $red;
                }
            }
            @media (max-width: 800px) {
                grid-template-columns: repeat(7, 60px);
                grid-template-rows: repeat(5, 60px);

                > li {
                    font-size: 18px;
                }
            }
            @media (max-width: 560px) {
                grid-template-columns: repeat(7, 40px);
                grid-template-rows: repeat(5, 40px);

                > li {
                    font-size: 14px;
                }
            }
            @media (max-width: 400px) {
                grid-template-columns: repeat(7, 30px);
                grid-template-rows: repeat(5, 30px);

                > li {
                    font-size: 10px;
                }
            }
        }
        > button {
            margin-top: 30px;
            margin-bottom: 88px;
            width: 100%;
            height: 70px;
            border: none;
            border-radius: 10px;
            background-color: $light-orage;
            color: white;
            font-family: 'Blinker';
            font-weight: 700;
            font-size: 20px;

            @media (max-width: 800px) {
                height: 60px;
                font-size: 18px;
            }
            @media (max-width: 560px) {
                height: 50px;
                font-size: 16px;
            }
            @media (max-width: 400px) {
                height: 40px;
                font-size: 14px;
            }
        }
    }
}