@import '/src/style/Color.scss';

.aisurvey-3 {
    .questions{
        h1{
            line-height: 135%;
        }
        div{
            line-height: 135%;
        }
    }
    
    #ai-survey-subject {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        column-gap: 35px;
        row-gap: 24px;
        > input {
            display: none;
            &:checked + label {
                border: 3px solid #000;
            }
        }
        > label.subject-options {
            font-family: 'Blinker';
            width: 128px;
            @media (max-width: 500px) {
                width: 90px;
            }
            border-radius: 10px;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            background-color: #FFECD9;
            font-weight: bold;
            padding: 16px 0;
        }
    }
}