@import '/src/style/Color.scss';

#home {
    overflow-x: hidden;
    background-color: $light-brown-3;
    width: 100%;
    position: relative;
    top: 40px;
    #contents {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 50px;
        padding-bottom: 90px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        .content {
            padding: 0px 4% 0px 4%;
            .content-header {
                // font-family: 'NanumSquare EB';
                display: flex;
                align-items: center;
                font-size: 2.5vh;

                >img {
                    width: 10vw;
                    max-width: 55px;
                }

                >div{
                    font-family: 'NanumSquareR';
                    >h3{
                        >span{
                            font-family: 'NanumSquare EB';
                        }
                    }
                    >div{
                        margin-top: 3.5px;
                    }
                }

                >div {
                    margin-left: 9px;

                    >div {
                        color: $gray-1;
                    }
                }
            }

            .content-section {
                margin-top: 10px;
                width: 100%;

                >img {
                    border-radius: 5px;
                    width: 100%;
                }

                >div {
                    display: flex;
                    width: 100%;
                    margin-top: 2.8px;
                    .paragraph-cover {
                        width: 65%;
                        font-size: 0.9rem;
                        font-family: 'NanumSquareL';
                    }

                    .division-line {
                        float: left;
                        width: 0.5px;
                        height: 50px;
                        background-color: $gray-1;
                        margin-right: 2%;
                    }

                    .book-info {
                        font-size: 0.75rem;
                        font-family: 'NanumSquare acB';
                        .author,
                        .publish {
                            margin-top: 2px;
                            font-family: 'NanumSquareR';
                            text-align: left;
                            color: $gray-1;
                        }
                    }
                }
            }
        }
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    .book-info {
        font-size: 0.6rem;
        .author,
        .publish {
            text-align: right;
            color: $gray-1;
        }
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:621px) and (max-width:767px) {
    .book-info {
        font-size: 0.55rem;
        .author,
        .publish {
            text-align: right;
            color: $gray-1;
        }
    }
    #home{
        #contents {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .content {
                .content-header {
                    // font-family: 'NanumSquare EB';
                    display: flex;
                    align-items: center;
                    font-size: 3.1vh;
                }
            }
        }
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media (min-width:421px) and (max-width:620px) {
    .book-info {
        font-size: 0.7rem;
        .author,
        .publish {
            text-align: right;
            color: $gray-1;
        }
    }
    #home{
        #contents {
            display: grid;
            grid-template-columns: 1fr;
            .content {
                .content-header {
                    // font-family: 'NanumSquare EB';
                    display: flex;
                    align-items: center;
                    font-size: 3.1vh;
                }
            }
        }
    }
}

@media all and (max-width:420px) {
    .book-info {
        font-size: 0.6rem;
        .author,
        .publish {
            text-align: right;
            color: $gray-1;
        }
    }
    #home{
        #contents {
            display: grid;
            grid-template-columns: 1fr;
            .content {
                .content-header {
                    // font-family: 'NanumSquare EB';
                    display: flex;
                    align-items: center;
                    font-size: 2vh;
                }
            }
        }
    }
}
