@import '/src/style/Color.scss';

.aisurvey-5 {
    .questions{
        h1{
            line-height: 135%;
        }
        div{
            width: 110%;
            line-height: 135%;
        }
    }
    #ai-book-title {
        width: 580px;
        color: $dark-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 500px) {
            width: 80%;
        }
        ::placeholder {
            color: $dark-gray;
        }
        > input {
            font-family: 'Blinker';
            font-weight: 400;
            font-size: 22px;
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
        #ai-division-line {
            height: 3px;
            width: 100%;
            background-color: $light-gray-1;
            margin: 9px 0 12px;
        }
        > div:last-child {
            text-align: center;
            font-family: 'Blinker';
            font-weight: 400;
            font-size: 16px;
            @media (max-width: 500px) {
                font-size: 12px;
            }
        }
    }
}