@import '/src/style/Color.scss';

#view-first-page {
    position: relative;
    height: 100vh;
    background-color: $light-brown-1;
    > a {
        position: absolute;
        top: 32px;
        right: 36px;
        width: 22vw;
        max-width: 120px;
        height: 4vh;
        border-radius: 30px;
        background-color: $light-orage;
        color: $dark-brown-1;
        text-align: center;
        font-size: 18px;
        font-family: 'BM Jua';
        text-decoration: none;
        line-height: 4.5vh;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        > h1 {
            font-family: 'Blinker';
            font-size: 80px;
            color: $brown;
            margin-bottom: 13px;
            @media (min-width:900px) {
                font-size: 130px;
            }
            @media (max-width:800px)and(min-width:601px) {
                font-size: 120px;
            }
            @media (max-width:600px)and(min-width:501px){
                font-size: 100px;
            }
            @media (max-width:500px)and(min-width:301px){
                font-size: 90px;
            }
            @media (max-width:300px)and(min-width:200px){
                font-size: 70px;
            }
        }
        >img{
            width: 20vw;
            max-width: 400px;
            @media (max-width:900px) {
                width: 40vw;
                min-width: 200px;
            }
        }
        div:last-child {
            font-family: 'BM Dohyeon';
            font-size: 24px;
            color: $brown;
            margin-top: 48px;
            margin-bottom: 50px;
            @media (max-width:430px) {
                font-size: 22px;
            }
            @media (max-width:300px) {
                font-size: 18px;
            }
        }
    }
}