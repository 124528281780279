@import '/src/style/Color.scss';

#write {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;

    h1 {
        color: $dark-brown-2;
        font-size: 25px;
        font-family: 'BM Dohyeon';
        margin-top: 58px;
    }

    #write-horizontal-line {
        height: 0.5px;
        width: 100%;
        background-color: $dark-gray;
        margin-top: 26px;
    }

    #write-contents {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-end;
        row-gap: 50px;
        column-gap: 50px;

        #select-book {
            height: 100%;

            h2 {
                font-size: 23px;
                font-family: 'BM Dohyeon';
            }

            #book-cover {
                border: 1px solid $light-gray-1;
                margin-top: 6px;
                width: 280px;
                height: 400px;
                background-color: $light-blue;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: 'Blinker';
                position: relative;

                >button {
                    border: none;
                    outline: none;
                    height: 30px;
                    width: 30px;
                    background-color: $dark-brown-1;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }

        #write-book {
            display: flex;
            justify-content: center;
            min-width: 460px;
            @media (max-width: 800px) {
                min-width: 340px;
            }
            width: 50%;
            max-width: 840px;
            
            #write-book-wrap {
                display: flex;
                flex-direction: column;
                border: 1px solid $light-gray-1;
                box-sizing: border-box;
                width: 100%;

                >h2 {
                    height: 45px;
                    background-color: $light-brown-1;
                    line-height: 45px;
                    text-align: center;
                    font-size: 18px;
                    font-family: 'Blinker';
                    color: $dark-brown-1;
                    font-weight: 600;
                }

                #write-book-form {
                    display: flex;
                    flex-direction: column;
                    row-gap: 5px;
                    margin: 10px;

                    >h4 {
                        color: $gray-1;
                        font-size: 14px;
                    }

                    .write-book-line {
                        height: 0.5px;
                        width: 100%;
                        margin: 0 auto;
                        background-color: $light-gray-1;
                        margin: 3px auto 10px;
                    }

                    input[type=text] {
                        margin: 0 auto;
                        width: 95%;
                        height: 30px;
                        outline: none;
                        border: none;
                        padding: 0;
                        background-color: $light-blue;
                        box-sizing: border-box;
                        padding: 14px;
                        border-radius: 30px;
                        font-size: 12px;
                        font-family: 'Blinker';
                    }

                    textarea {
                        margin: 0 auto;
                        width: 95%;
                        height: 195px;
                        outline: none;
                        border: none;
                        padding: 0;
                        background-color: $light-blue;
                        box-sizing: border-box;
                        padding: 14px;
                        border-radius: 5px;
                        font-size: 12px;
                        font-family: 'Blinker';
                    }
                }

                .show-image-file {
                    padding: 10px;
                    border-top: 1px solid #000;

                    #image-file {
                        height: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border: 1px dashed #000;
                        cursor: pointer;

                        >img {
                            width: 100%;
                        }
                    }
                }

                #write-book-footer {
                    display: grid;
                    grid-template-columns: 1fr 80px;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    border-top: 1px solid $light-gray-1;
                    height: 45px;

                    #write-book-options {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        #write-left-options {
                            margin-left: 12px;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;

                            #write-left-image-input {
                                >label {
                                    display: flex;
                                    align-items: center;
                                    column-gap: 5px;
                                    background-color: $light-brown-1;
                                    padding: 8px;
                                    border-radius: 10px;
                                }

                                >input[type=file] {
                                    display: none;
                                }
                            }
                            > button#delete-image-file {
                                background-color: $light-brown-1;
                                border: none;
                                outline: none;
                                padding: 8px;
                                border-radius: 10px;
                            }
                        }

                        #write-right-options {
                            display: flex;
                            margin-right: 12px;

                            label {
                                font-size: 14px;
                            }
                        }
                    }

                    > button#write-book-upload {
                        background: $light-orage;
                        height: 100%;
                        border: none;
                    }
                }
            }
        }
    }
}