@import '/src/style/Color.scss';

#ai-survey-result {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    >img{
        width: 12vw;
        min-width: 200px;
    }
    > button {
        width: 330px;
        height: 90px;
        border: none;
        border-radius: 15px;
        background-color: $light-orage;
        color: white;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 30%;
    }
}