@import '/src/style/Color.scss';

#sign-up-pop-up--bg {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    >img{
        width: 300px;
        position: relative;
        top:135px;
    }
    #sign-up-pop-up-container {
        margin-bottom: 300px;
        min-width: 320px;
        min-height: 305px;
        border-radius: 40px;
        background-color: white;
        font-family: 'Blinker';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >h2 {
            margin-top: 65px;
            font-size: 33px;
            font-weight: bold;
            text-align: center;
            line-height: 1.2;
        }
        >button {
            margin-top: 30px;
            cursor: pointer;
            font-size: 15px;
            border-radius: 40px;
            border: 1px solid $gray-2;
            width: 140px;
            height: 40px;
            color: white;
            background-color: $light-orage;
        }

        #do-not-show {
            color: $gray-2;
            text-decoration: underline $gray-2;
            margin-top: 20px;
            cursor: pointer;
            user-select: none;
        }

        #sign-up-line {
            height: 2px;
            width: 30px;
            background-color: $orange;
            margin-top: 28px;
        }
    }
}