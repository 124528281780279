@import '/src/style/Color.scss';

#goods-list-title {
    font-size: 40px;
    font-family: 'BM Dohyeon';
    margin-left: 4rem;
}

#goods-list {
    margin: 3rem 4rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    text-align: center;
    row-gap: 2rem;

    >a {
        text-decoration: none;

        >li {
            font-family: 'Blinker';

            >img {
                height: 18rem;
                width: 20rem;
            }

            >h6 {
                font-size: 18px;
                color: #000;
            }

            >div {
                font-size: 14px;
                color: $gray-1;
            }
        }
    }
}