@import '/src/style/Color.scss';

#sign-up {
    height: 90%;
    @media (min-height:655px) and (max-height:700px) {
        height: 105%;
    }
    @media ALL and (max-height:654px) {
        height: 110%;
    }
    
    form {
        position: relative;
        width: 100%;
        margin: 0 auto;
        max-width: 400px;
        margin-top: 22px;
        align-items: center;
        #input-form {
            display: flex;
            flex-direction: column;
            row-gap: 22px;
            width: 90%;
            margin: 0 5%;
            > div {
                display: flex;
                flex-direction: column;

                > label {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
                > input {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    box-sizing: border-box;
                    padding: 0 20px;
                    background-color: $light-blue;
                    border-radius: 5px;
                }
            }
            #email-gender-container {
                display: grid;
                grid-template-columns: 69.6% 48*2+6px;
                column-gap: 6px;
                @media ALL and (max-width:400px) {
                    grid-template-columns: 69% 48*2+6px;
                }
                @media ALL and (max-width:370px) {
                    grid-template-columns: 66% 48*2+6px;
                }
                @media ALL and (max-width:345px) {
                    grid-template-columns: 63% 48*2+6px;
                }
                @media ALL and (max-width:291px) {
                    grid-template-columns: 57% 48*2+6px;
                }
                > #email-container {
                    display: flex;
                    flex-direction: column;
    
                    > label {
                        font-size: 18px;
                        margin-bottom: 8px;
                    }
                    > input {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        height: 50px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        background-color: $light-blue;
                        border-radius: 5px;
                    }
                }
                > #gender-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > div:not([id=gender-input]) {
                        font-size: 18px;
                        margin-bottom: 8px;
                    }

                    > #gender-input {
                        display: flex;
                        column-gap: 6px;
                        > label {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48px;
                            height: 48px;
                            border-radius: 5px;
                            box-sizing: border-box;

                            &:nth-child(2) {
                                background-color: #99C8FF;
                            }
                            &:nth-child(4) {
                                background-color: #FFC1C1;
                            }
                        }
                        > input {
                            display: none;

                            &:nth-child(1):checked+.gender-label {
                                border: 3px solid #000;
                            }
                            &:nth-child(3):checked+.gender-label {
                                border: 3px solid #000;
                            }
                        }
                    }
                }
            }
            #address-container {
                > div {
                    display: flex;
                    height: 50px;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 10px 0 20px;
                    background-color: $light-blue;
                    border-radius: 5px;

                    > input {
                        width: 100%;
                        background-color: $light-blue;
                    }
                    >a {
                        > button {
                            border: none;
                            outline: none;
                            height: 30px;
                            width: 30px;
                            background-color: $dark-brown-1;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
                #detail-address {
                    margin-top: 12px;
                }
            }

            #birth-container {
                width: 100%;
                >label{
                    float: left;
                }
                > div {
                    width: 100%;
                    display: flex;
                    column-gap: 8px;
                    > div {
                        display: flex;
                        height: 50px;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 10px;
                        background-color: $light-blue;
                        border-radius: 5px;

                        > input {
                            background-color: $light-blue;
                            text-align: right;
                        }
                    }
                    #birth-year {
                        width: 100%;
                        > input {
                            width: 100%;
                        }
                    }
                    #birth-month {
                        width: 50%;
                        > input {
                            width: 100%;
                        }
                    }
                    #birth-day {
                        width: 50%;
                        > input {
                            width: 100%;
                        }
                    }
                }
            }
        }
        #sign-up-button {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            > button {
                width: 90%;
                margin-left: 10%;
                margin-right: 10%;
                height: 65px;
                border: none;
                border-radius: 10px;
                background-color: $light-orage;
                color: white;
                font-family: 'Blinker';
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
}